import React, { useEffect, useState } from 'react'
import styles from "./TaskRow.module.css"
import { set } from 'lodash'

export default function TaskRow({ task, handleStatus, handleOpen }) {
    const [deliveryMsg, setDeliveryMsg] = useState(null)
    const [readableDate, setReadableDate] = useState(null)
    useEffect(() => {
        let currentStatus = handleStatus(task)
        setDeliveryMsg(currentStatus?.msg)
        setReadableDate(currentStatus?.readableDate)

    })
    return (
        <div className={styles.taskContainer}>
            <div className={styles.idContainer}>
                #{task?.orderId?.substring(task?.orderId?.length - 5)}
            </div>
            <div className={styles.orderContainer}>
                {task?.skuName}
            </div> 
            <div className={styles.statusContainer}>
                <p>{deliveryMsg}</p>

                <p>{readableDate}</p>
            </div>  
            <div className={styles.respondBtn} onClick={ () => {handleOpen(task)} }>
                {(task?.status === "picked" ||
                    task?.status === "delivered" ||
                        task?.status === "cancelled" ||  task?.status === "ready") ?
                            "View"
                            :
                            "Respond"
                }
            </div>   
        </div>
    )
}
