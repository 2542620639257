import { Navigate, Outlet } from "react-router-dom";
import { useStore } from "react-redux";
const PrivateRoutes = () => {
	const store = useStore();

	let auth = store?.getState()?.user?.isLogged;
	return auth ? <Outlet /> : <Navigate to="../login" />;
};

export default PrivateRoutes;
