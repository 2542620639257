import React, { useEffect, useState } from "react";
import styles from "./PaymentRow.module.css";

export default function PaymentRow({ data,  handleOpen }) {
    const [largeScreenData, setLargeScreenData] = useState(window.innerWidth >= 944);
  useEffect(() => {const handleResize = ()=>{
    setLargeScreenData(window.innerWidth >= 944)
  }
    window.addEventListener('resize', handleResize)},[])
  
  return (
    <div className={styles.taskContainer}>
      <div className={styles.idContainer}>
        #{data?.transferId?.substring(data?.transferId?.length - 5)}
      </div>
      {largeScreenData?<> 
      <div className={styles.orderContainer}>
        {new Date(data?.createdTime).toLocaleDateString("en-US")}
      </div>
      <div className={styles.statusContainer}>
        <span>${data?.total}</span>
      </div>
      <div className={styles.datesContainer}>
        <span>
          #{data?.taskId?.substring(data?.taskId?.length - 5)}
        </span>
      </div>
      <div className={styles.statusContainer}>
        <span>{data?.status}</span>
      </div></>:
      <>
      < div className={styles.paid}>Paid</div>
      <div className={styles.paidwhenandhowmuch}>
       <span>
       ${data?.total}
        </span> 
        <span>
            {new Date(data?.createdTime).toLocaleDateString("en-US")}
            </span>
      </div>
      </>}
      <div
        className={styles.respondBtn}
        onClick={() => {
          handleOpen(data?.id);
        }}
      >
        {"View"}
      </div>
    </div>
  );
}
