import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "app",
	initialState: {
		taskIds:localStorage.getItem('taskIds')=== null ||
		 localStorage.getItem('taskIds') ===''||
		 localStorage.getItem('taskIds') ===undefined
		 	?[]
			:JSON.parse(localStorage.getItem('taskIds')),// app store
		filterId:
			localStorage.getItem("filterId") === null ||
			localStorage.getItem("filterId") === ""
				? "new"
				: JSON.parse(localStorage.getItem("filterId")),//app store
		triggerReloadAPI: false, //app store
	},
	reducers: {
		// action => action handler
		TASK_IDS:(state,action) => {
			state.taskIds = action.payload;
			localStorage.setItem("taskIds", JSON.stringify(action.payload))
		},
		SAVE_FILTER_ID: (state, action) => {
			state.filterId = action.payload;
			localStorage.setItem("filterId", JSON.stringify(action.payload));
		},//app store
		TRIGGER_RELOAD_API: (state, action) => {
			state.triggerReloadAPI = action.payload;
		}
	},
});

export const { SAVE_FILTER_ID,  TASK_IDS,TRIGGER_RELOAD_API} = slice.actions;

export default slice.reducer;
