import React, { useEffect } from "react";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { SAVE_FILTER_ID } from "../../store/app";

function Sidebar() {
	const links = [
		{
			id: "new",
			title: "New Orders",
		},
		{
			id: "confirmed",
			title: "Confirmed Orders",
		},
		{
			id: "ready",
			title: "Ready For Pickup",
		},
	];

	const filterId = useSelector((state) => state.app.filterId);

	const dispatch = useDispatch();

	const handleSelectFilter = (e, id) => {
		dispatch(SAVE_FILTER_ID(id));
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.filterMainContainer}>
				{links.map((item) => {
					return filterId && item?.id === filterId ? (
						<div
							onClick={(e) => handleSelectFilter(e, item?.id)}
							className={styles.filterCell}
							id={item.id}
							key={item.id}
						>
							<div className={styles.activeDiv}>{item.title}</div>
						</div>
					) : (
						<div
							onClick={(e) => handleSelectFilter(e, item?.id)}
							className={styles.filterCell}
							id={item.id}
							key={item.id}
						>
							<div className={styles.innerDiv}>{item.title}</div>
						</div>
					);
				})}
			</div>
			<div className={styles.linkContainer}>
				<Link to="/order-history" className={styles.link}>
					<div className={styles.innerDiv}> Order History</div>
				</Link>
				<Link to="/settings" className={styles.link}>
					<div className={styles.innerDiv}> Settings</div>
				</Link>
			</div>
		</div>
	);
}

export default Sidebar;
