import React,{useState} from 'react';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import  './FilterSelect.css'

const FilterSelect = ({setSelectedFilter,selectedFilter,handleFilterInventory,filterOptions}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const todayDate = new Date();
 
  const [selectedOptionMonth, setSelectedOptionMonth] = useState(Number(todayDate.getMonth().toString())+1);
  const [selectedOptionYear, setSelectedOptionYear] = useState(Number(todayDate.getFullYear()));
  const optionsList = filterOptions
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };
  const [showMonths,setShowMonths] = useState(false)
  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOptionYear(optionsList[index].year);
    
    setShowMonths(state=>!state)
    // setIsOptionsOpen(false);
  };
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };
  
  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOptionMonth(
          selectedOptionMonth - 1 >= 0 ? selectedOptionMonth - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOptionMonth(
          selectedOptionMonth == optionsList.length - 1 ? 0 : selectedOptionMonth + 1
        );
        break;
      default:
        break;
    }
  };
const handleSelectFilter = (index, month, year) => {
  setSelectedOptionYear(year)
  setSelectedOptionMonth(month)
  setSelectedFilter({year:year,month:month})
  handleFilterInventory({year:year, month:month})
}
const handleOnclickClear=()=>{
  handleSelectFilter(0,Number(todayDate.getMonth().toString())+1,Number(todayDate.getFullYear()))
}
  return (
    <div className={"wrapper-ph"}>
      <div className={"container-ph"}>
        <button id='filter-button-ph'
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={isOptionsOpen ? "expanded" : ""}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
        >
          <div className="option-button-text-ph">
            <span className="option-text-ph">{months[selectedFilter.month-1]+" "+selectedFilter.year}</span>
          {isOptionsOpen ?<KeyboardArrowUpIcon viewBox='0 0 23 23' fontSize='small'/>
          :<ExpandMoreIcon viewBox='0 0 23 23' fontSize='small'/>}
          </div>
        </button>
        <ul
          className={`options-ph ul-offset-show-ph-${selectedOptionYear} ${isOptionsOpen ? "show-ph" : ""}`}
          role="listbox"
          aria-activedescendant={optionsList[selectedOptionYear]}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
        >  
        <div className="filter-header-ph">
          <span>Filter</span>
          <span className="clear-button" onClick={handleOnclickClear}>Clear</span>
        </div>
        <Divider light style={{marginBottom:"6px"}}/>
           {optionsList.map((option, index) => (
            <li key={index}
              id={option.year}
              role="option"
              aria-selected={selectedOptionYear == index}
              tabIndex={0}
              onKeyDown={handleKeyDown(index)}
              onClick={() => {
                setSelectedThenCloseDropdown(index);
              }}
            >
              <div style={{display: "flex", alignItems: "center",}}>
              {showMonths&&option.year===selectedOptionYear?
              <ArrowDropDownSharpIcon/>
              :
              <ArrowRightSharpIcon/>
            }
              <span style={{width:"100%"}}>{option.year}</span>
              </div>
              {showMonths&&option.year===selectedOptionYear&&<ul className="month-list-options">
                {option.months.map((month, index) =>{
                  let currentMonth=month
                  return <li key={`${option.year} ${currentMonth}`} className={(selectedFilter.year===option.year&&selectedFilter.month===month)?"active-list-item-month-ph":"list-item-month-ph"}  onClick={()=>handleSelectFilter(index,currentMonth,option.year)}>{months[month-1]}</li>})}
                </ul>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterSelect
