import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from "react-places-autocomplete";
import styles from "./ProfileSettings.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import ClipLoader from "react-spinners/ClipLoader";
import EmailModal from "./EmailModal/EmailModal";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { getUserUrl } from "../../../env/APIManager";
import { useFetch } from "../../../useFetch";

const toastSettings = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

export default function ProfileSettings() {
	const PNF = PhoneNumberFormat;
	const phoneUtil = PhoneNumberUtil.getInstance();

	const [token, setToken] = useState(null);
	// const [loading, setLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [state, setState] = useState({
		firstName: "",
		lastName: "",
		businessName: "",
		phoneNumber: "",
		primaryAddress: "",
		email: "",
	});
	const [previousState, setPreviousState] = useState([]);
	const [addressState, setAddressState] = useState({});

	const navigate = useNavigate();

	const handleBackClick = async () => {
		navigate("/settings");
	};

	function handleInput(event) {
		event.target.value = event.target.value.replace(/[^0-9()\s-]/g, "");
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const isStateChanged = Object?.entries(state)?.some(
			([key, value]) => previousState[key] !== value
		);

		if (state?.phoneNumber?.length < 5) {
			toast.error(
				"Please verify that the number you have entered is valid and try again",
				{ theme: "colored" }
			);
			return;
		}
		const formattedRequest = state;
		const parsedNumber = phoneUtil.parse(formattedRequest?.phoneNumber, "US");
		const isValidNumber = phoneUtil.isValidNumber(parsedNumber);

		if (validator.isEmail(state?.email)) {
			// do something
		}
		if (!phoneUtil.isValidNumber(parsedNumber)) {
			toast.error(
				"Please verify that the number you have entered is valid and try again",
				{
					theme: "colored",
				}
			);
			return;
		}

		if (addressState && Object.keys(addressState)?.length === 0) {
			toast.error(
				"Please verify that the address you have entered is valid and try again",
				{
					theme: "colored",
				}
			);
			return;
		}
		if (isStateChanged && isValidNumber) {
			setSaveLoading(true);
			formattedRequest.address = addressState;
			formattedRequest.phoneNumber = phoneUtil.format(parsedNumber, PNF.E164);

			let arg = {
				url: getUserUrl,
				method: "PATCH",
				data: {
					userInfo: formattedRequest,
				},
				auth: true,
			};

			const resp = await fetchFuntion(arg);

			// updateUser(token, formattedRequest).then((resp) => {
			if (resp?.code === "SUCCESS") {
				const updatedData = resp?.updateParams;
				updatedData.phoneNumber = formatNumberNational(
					updatedData?.phoneNumber
				);
				setState(updatedData);
				setPreviousState(updatedData);
				setSaveLoading(false);
				toast.success("Your changes have been saved!", toastSettings);
			}
			// });
		} else if (!isValidNumber) {
			toast.error(
				"Please verify that the number you have entered is valid and try again!",
				toastSettings
			);
		} else {
			toast.success("Your changes have been saved!", toastSettings);
		}
	};

	const handleSuggestion = (address) => {
		return geocodeByAddress(address)
			.then((results) => {
				const place = results[0];

				setState({ ...state, primaryAddress: place?.formatted_address });
				setAddressState({
					...addressState,
					formatted: place?.formatted_address,
				});

				place?.address_components?.forEach((component) => {
					switch (component?.types[0]) {
						case "locality":
							setAddressState((prev) => ({
								...prev,
								city: component?.long_name,
							}));
							break;
						case "administrative_area_level_1":
							setAddressState((prev) => ({
								...prev,
								state: component?.short_name,
							}));
							break;
						case "postal_code":
							setAddressState((prev) => ({
								...prev,
								zipCode: component?.long_name,
							}));
							break;
						case "country":
							setAddressState((prev) => ({
								...prev,
								country: component?.long_name,
							}));
							break;
						default:
							break;
					}
				});
				return getLatLng(results[0]);
			})
			.then(({ lat, lng }) => {
				setAddressState((prev) => ({ ...prev, lat: lat, lng: lng }));
			})
			.catch((error) => console.error("Error", error));
	};

	const formatNumberNational = (number) => {
		const parsedNumber = phoneUtil?.parse(number, "US");
		const isValidNumber = phoneUtil?.isValidNumber(parsedNumber);

		if (isValidNumber) {
			return phoneUtil?.format(parsedNumber, PNF.NATIONAL);
		}
		return number;
	};

	// useEffect(() => {
	// 	setLoading(true);
	// 	if (localStorage.getItem("token")?.length > 0) {
	// 		setToken(JSON.parse(localStorage.getItem("token").trim()));
	// 	} else {
	// 		navigate("../login");
	// 	}
	// }, []);

	let arg = {
		url: getUserUrl,
		method: "GET",
		auth: true,
	};

	const { result, loading, fetchFuntion, fetchLoading } = useFetch(arg);

	let user = result?.user;

	useEffect(() => {
		if (user) {
			setUserInfo();
		}
	}, [user]);

	async function setUserInfo() {
		if (user?.primaryAddress) {
			await handleSuggestion(user?.primaryAddress);
		}
		setState({
			firstName: user?.firstName ?? "",
			lastName: user?.lastName ?? "",
			businessName: user?.businessName ?? "",
			phoneNumber: user?.phoneNumber
				? formatNumberNational(user?.phoneNumber)
				: "",
			primaryAddress: user?.primaryAddress ?? "",
			email: user?.email ?? "",
		});
		setPreviousState({
			firstName: user?.firstName ?? "",
			lastName: user?.lastName ?? "",
			businessName: user?.businessName ?? "",
			phoneNumber: user?.phoneNumber
				? formatNumberNational(user?.phoneNumber)
				: "",
			primaryAddress: user?.primaryAddress ?? "",
			email: user?.email ?? "",
		});
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.navbar}>
					<div className={styles.backBtn}>
						<ArrowBackIcon onClick={handleBackClick} fontSize="large" />
					</div>
					<div className={styles.title}>Profile Settings</div>
				</div>
				{loading ? (
					<div id={styles.clipLoader}>
						<ClipLoader color="#000" size={100} />
					</div>
				) : (
					<form onSubmit={handleSubmit}>
						<div className={styles.inputFields}>
							<div className={styles.inputRow}>
								<label className={styles.inputField}>
									<div className={styles.inputHeader}>
										<span>First Name</span>
										<span id={styles.required}>Required</span>
									</div>
									<input
										id={styles.styledInput}
										required={true}
										type="text"
										value={state?.firstName}
										onChange={(event) =>
											setState({ ...state, firstName: event?.target?.value })
										}
									/>
								</label>
								<label className={styles.inputField}>
									<div className={styles.inputHeader}>
										<span>Last Name</span>
										<span id={styles.required}>Required</span>
									</div>
									<input
										id={styles.styledInput}
										required={true}
										type="text"
										value={state?.lastName}
										onChange={(event) =>
											setState({ ...state, lastName: event?.target?.value })
										}
									/>
								</label>
							</div>
							<div className={styles.inputRow}>
								<label className={styles.inputField}>
									<div className={styles.inputHeader}>
										<span>Store Name</span>
										<span id={styles.required}>Required</span>
									</div>
									<input
										id={styles.styledInput}
										required={true}
										type="text"
										value={state?.businessName}
										onChange={(event) =>
											setState({ ...state, businessName: event?.target?.value })
										}
									/>
								</label>
								<label className={styles.inputField}>
									<div className={styles.inputHeader}>
										<span>Store Phone</span>
										<span id={styles.required}>Required</span>
									</div>
									<input
										id={styles.styledInput}
										required={true}
										type="tel"
										value={state?.phoneNumber}
										onChange={(event) =>
											setState({ ...state, phoneNumber: event?.target?.value })
										}
										onInput={handleInput}
									/>
								</label>
							</div>
							<PlacesAutocomplete
								value={state?.primaryAddress}
								onChange={(value) =>
									setState({ ...state, primaryAddress: value })
								}
								onSelect={handleSuggestion}
							>
								{({
									getInputProps,
									suggestions,
									getSuggestionItemProps,
									loading,
								}) => (
									<div>
										<div className={styles.inputRow}>
											<label className={styles.inputField}>
												<div className={styles.inputHeader}>
													<span>Store Address</span>
													<span id={styles.required}>Required</span>
												</div>
												<input
													id={styles.styledInput}
													required={true}
													type="text"
													onClick={() => {
														setState({ ...state, primaryAddress: "" });
														setAddressState({});
													}}
													{...getInputProps()}
												/>
											</label>
										</div>
										<div>
											{loading && (
												<div style={{ width: "100%", textAlign: "center" }}>
													<ClipLoader color="#000" size={50} />
												</div>
											)}
											<div>
												{suggestions.map((suggestion) => {
													const style = {
														backgroundColor: suggestion.active
															? "#fff"
															: "#f4f4f4",
													};
													return (
														<div
															className={styles.suggestionBox}
															key={suggestion?.placeId}
															{...getSuggestionItemProps(suggestion, { style })}
														>
															{suggestion.description}
														</div>
													);
												})}
											</div>
										</div>
									</div>
								)}
							</PlacesAutocomplete>
							<div className={styles.inputRow}>
								<div className={styles.inputField}>
									<div
										onClick={() => setIsOpen(true)}
										className={styles.inputEmailHeader}
									>
										<span>Email</span>
										<span style={{ display: "flex", alignItems: "center" }}>
											<InfoIcon sx={{ fontSize: "15px" }} />
										</span>
									</div>
									<input
										id={styles.styledInput}
										required={true}
										disabled
										value={state?.email}
										type="text"
										onChange={(event) =>
											setState({ ...state, email: event?.target?.value })
										}
									/>
								</div>
							</div>
							<div style={{ margin: "0 auto", position: "relative" }}>
								<button
									className={styles.saveBtn}
									type="submit"
									disabled={saveLoading}
								>
									<div className={styles.saveBtnText}>Save Changes</div>
									{(saveLoading || fetchLoading) && (
										<span className={styles.saveLoader}>
											<ClipLoader color="#FFF" size={30} />
										</span>
									)}
								</button>
							</div>
						</div>
					</form>
				)}
				<EmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
				<ToastContainer />
			</div>
		</>
	);
}
