import { useState, useEffect } from 'react'
import styles from './PayoutDetails.module.css'
import { getPayoutDetails } from '../../../../env/APIManager';
import { Divider } from '@mui/material';
import { ClipLoader } from 'react-spinners'
import CloseIcon from '@mui/icons-material/Close';
import { useFetch } from '../../../../useFetch';
export default function PayoutDetails({ paymentId, handleCloseModal, goToPaymentId }) {
    const handleOnClickCloseButton = (e) => {
        handleCloseModal()
    }
    const [payment, setPayment] = useState({})


    let arg = {
        url: getPayoutDetails + paymentId,
        method: "GET",
        auth: true
    };
//     function useFetch(arg) {
//         return{
//         result: { code: 'SUCCESS', data: { status: 'Paid', scheduled: 1635734400000, transferIds: ['1', '2'], amount: 100 } },
//         loading: false
//     }
// }
    const { result, loading } = useFetch(arg);
    useEffect(() => {
        // getPaymentDetailsFor1Payment()
        if (result?.code === 'SUCCESS') {
            setPayment(result?.data)
        }

    }, [result])

    return (

        <div className={styles["Modal-container"]}>
            <div className={styles["close-button"]} onClick={handleOnClickCloseButton}><CloseIcon /></div>
            <div className={styles["modal-body"]}>
            <div className={styles["Header-modal"]}>
                
                <span className={styles["header-modal-paymentid"]}>#{paymentId?.substring(paymentId?.length-5)}</span>
                
            </div>
            {loading ? (
                <div className={styles["spinner"]}>
                    <ClipLoader size={100} color="#8585ff" />
                </div>
            ) : <>

                <div className={styles['model-payment-status-div']}>
                    <span className={styles["modal-payment-status"]}>Status: {payment?.status}</span>
                </div>
                <div className={styles["body-box-container"]}>
                    <div className={styles["left-box-container"]}>
                        <div className={styles["left-top-box"]}>
                            <span style={{ fontWeight: 700, marginBottom: "20px" }}>Payout Date</span>
                            <span>{new Date(payment?.scheduled).toLocaleDateString("en-US")}</span>
                        </div>
                        <div className={styles['left-bot-box']}>
                            <span style={{ fontWeight: 700, }}>Transfers</span>
                            <div style = {{ fontWeight: 500 , textDecoration:"underline" }}  > {payment?.transactions?.map((payment)=>{
                                return (
                                <span key={payment} className={styles['pointer']} onClick={() => goToPaymentId?.(payment)}>
                                    {payment}<br/>   
                                </span>
                            )})}
                            
                            </div>
                        </div>
                    </div>
                    <div className={styles["right-box-container"]}>
                        <div className={styles["right-box"]}>
                            <span className={styles["right-box-header"]}>Payout Details</span>
                            <div className={styles["right-box-divider-divider"]}>
                                <Divider light style={{ marginBottom: "6px", border: "1px solid #000000" }} />
                            </div>
                            

                            {payment?.amount > 0 && <div className={styles["right-box-divider"]}>
                                <span>Amount:</span>
                                <span>${payment?.amount}</span>
                            </div>}

                            <div className={styles["right-box-divider-divider"]}>
                                <Divider light={false} style={{ marginTop: "6px", border: "1px solid #000000" }} />
                            </div>

                            <div className={styles["right-box-divider-amount"]}>
                                <span style={{ fontFamily: "Mulish", fontWeight: 700 }}>Payout Amount:</span>
                                <span>${payment?.amount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            </div>
        </div>

    )
}