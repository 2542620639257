import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import StoreIcon from "@mui/icons-material/Store";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import PaymentsIcon from "@mui/icons-material/Payments";
export const links = [
    {
        id: "profile",
        title: "Profile Settings",
        link: "/settings/profile-settings",
        icon: <ManageAccountsIcon />,
    },
    {
        id: "business-hours",
        title: "Hours of Operation",
        link: "/settings/business-hours",
        icon: <WatchLaterIcon />,
    },
    {
        id: "inventory",
        title: "Inventory Management",
        link: "/settings/inventory-management",
        icon: <StoreIcon />,
    },
    {
        id: "banking-information",
        title: "Banking Information",
        icon: <AccountBalanceWalletIcon />,
    },
    {
        id: "Dashboard",
        title: "Dashboard",
        link: "/settings/dashboard",
        icon: <AnalyticsIcon />,
    },
    {
        id: "payment-history",
        title: "Payment History",
        link: "/settings/payment-history",
        icon: <PaymentsIcon />,
    },
];