import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "user",
	initialState: {
		isLogged:
			localStorage.getItem("isLogged") === null ||
			localStorage.getItem("isLogged") === ""
				? false
				: JSON.parse(localStorage.getItem("isLogged")),// user store
				
		createdAt:
			localStorage.getItem("createdAt") === null ||
			localStorage.getItem("createdAt") === ""
				? false
				: JSON.parse(localStorage.getItem("createdAt")),// user store
		
	},
	reducers: {
		
		LOG_USER: (state, action) => {
			state.isLogged = action.payload;
			localStorage.setItem("isLogged", JSON.stringify(action.payload));// user store
		},
		LOGOUT_USER: (state, action) => {
			state.isLogged = action.payload;
			localStorage.clear();

		},// user store
		CREATED_AT: (state, action) => {
			state.createdAt= action.payload;
			localStorage.setItem("createdAt", JSON.stringify(action.payload))// user store
		},
	},
});

export const { LOG_USER, LOGOUT_USER, CREATED_AT} = slice.actions;

export default slice.reducer;
