import React, { useEffect, useState } from "react";
import styles from "./PayoutRow.module.css";

export default function PayoutRow({ data,  handleOpen }) {
    const [largeScreenData, setLargeScreenData] = useState(window.innerWidth >= 944);
 
  useEffect(() => {const handleResize = ()=>{
    setLargeScreenData(window.innerWidth >= 944)
  }
    window.addEventListener('resize', handleResize)},[])
  
  return (
    <div className={styles.taskContainer}>
      <div className={styles.idContainer}>
        #{data?.payoutId?.substring(data?.payoutId?.length - 5)}
      </div>
      {largeScreenData?<> 
      <div className={styles.statusContainer}>
        <span>${data?.amount}</span>
      </div>
      <div className={styles.orderContainer}>
        {new Date(data?.scheduled).toLocaleDateString("en-US")}
      </div>
      <div className={styles.statusContainer}>
        <span>{data?.status}</span>
      </div></>:
      <>
      < div className={styles.paid}>{data?.status}</div>
      <div className={styles.paidwhenandhowmuch}>
       <span>
       ${data?.amount}
        </span> 
        <span>
            {new Date(data?.scheduled).toLocaleDateString("en-US")}
            </span>
      </div>
      </>}
      <div
        className={styles.respondBtn}
        onClick={() => {
          handleOpen(data?.payoutId);
        }}
      >
        {"View"}
      </div>
    </div>
  );
}
