import React, { useEffect, useState, useRef } from "react";
import styles from "./StoreHours.module.css";
import EditModal from "./EditModal/EditModal";
import NewSpecialModal from "./NewSpecialModal/NewSpecialModal";
import EditSpecialModal from "./EditSpecialModal/EditSpecialModal";
import ClipLoader from "react-spinners/ClipLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getUser,getUserUrl  } from "../../../env/APIManager";
import { useFetch } from "../../../useFetch";


const hours = [...Array(24)].map((_, i) => i);

const toastSettings = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

const selectorStyle = {
	position: "absolute",
	right: 10,
	top: "50%",
	transform: "translateY(-50%)",
	color: "black",
	pointerEvents: "none",
};

const checkBoxStyles = { "&.Mui-checked": { color: "#6C40FC" } };

export default function StoreHours() {
	const [token, setToken] = useState(null);
	const [regularHours, setRegularHours] = useState(null);
	const [specialHours, setSpecialHours] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isSpecialOpen, setIsSpecialOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [timeSlot, setTimeSlot] = useState(null);
	const [regularLoaded, setRegularLoaded] = useState(false);
	const [specialLoaded, setSpecialLoaded] = useState(false);

	const elementRef = useRef(null);
	const weekDays = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];

	const editProps = {
		regularHours,
		setRegularHours,
		isOpen,
		setIsOpen,
		weekDays,
		hours,
		token,
		setRegularLoaded,
		toastSettings,
		selectorStyle,
	};

	const specialProps = {
		specialHours,
		setSpecialHours,
		hours,
		token,
		setSpecialLoaded,
		toastSettings,
		selectorStyle,
	};

	const navigate = useNavigate();

	const handleBackClick = async () => {
		navigate("/settings");
	};

	const toggleEditHours = async () => {
		setIsOpen((prev) => !prev);
		// Disable the overflow while the modal is up, needed for bigger screens.
		// elementRef.current.style.overflow = isOpen ? 'auto' : 'hidden';
		elementRef.current.scrollTop = "0";
	};

	const toggleSpecialHours = async () => {
		setIsSpecialOpen((prev) => !prev);
		// elementRef.current.style.overflow = isSpecialOpen ? 'auto' : 'hidden';
		elementRef.current.scrollTop = "0";
	};

	const toggleEditSpecialHours = (slot) => {
		if (slot) {
			setTimeSlot(slot);
		}
		setIsEditOpen((prev) => !prev);
		// elementRef.current.style.overflow = isEditOpen ? 'auto' : 'hidden';
		elementRef.current.scrollTop = "0";
	};

	const getHours = (hour) => {
		var AmOrPM = hour >= 12 ? "PM" : "AM";
		return (hour % 12 || 12).toString().padStart(2, "0") + ":00" + AmOrPM;
	};
	const getHoursSmall = (hour) => {
		var AmOrPM = hour >= 12 ? " PM" : " AM";
		return (hour % 12 || 12).toString() + AmOrPM;
	};
	const getWeekDay = (weekId) => {
		return weekDays[weekId];
	};

	const matchDay = (index) => {
		const matchedDict = regularHours.find(
			(item) => item?.dayOfWeekId === index
		);
		if (matchedDict) {
			if (matchedDict?.openAllDay) {
				return "Open All Day";
			} else if (matchedDict?.closedAllDay) {
				return "Closed All Day";
			}
			return matchedDict?.openTime && matchedDict?.closeTime
				? `${getHoursSmall(matchedDict?.openTime)} - ${getHoursSmall(
						matchedDict?.closeTime
				  )}`
				: "Not Selected";
		} else {
			return "Not Selected";
		}
	};
	const getDateString = (timestamp) => {
		var formattedString = "";
		const date = new Date(timestamp);

		date
			.toDateString()
			.split(" ")
			.forEach((item, index) => {
				if (index === 0 || index === 2) formattedString += item + ", ";
				else {
					formattedString += item + " ";
				}
			});

		return formattedString;
	};

	const sortDays = (data) => {
		if (data?.length > 1) {
			data?.sort((a, b) => a?.date - b?.date);
		}

		return data;
	};

	useEffect(() => {
		// setLoading(true);
		if (localStorage.getItem("token")?.length > 0) {
			setToken(JSON.parse(localStorage.getItem("token").trim()));
		} else {
			navigate("../login");
		}
	}, []);


	let arg = {
		url: getUserUrl,
		method: "GET",
		auth: true,
	};
	const { result} = useFetch(arg);

	let user = result?.user;

	useEffect(() => {
			setRegularLoaded(false);
			setSpecialLoaded(false);
			// getUser(token).then((response) => {
				setRegularHours(user?.business_hours);
				setSpecialHours(sortDays(user?.special_hours));
				setRegularLoaded(true);
				setSpecialLoaded(true);
			// });
		
	}, [user]);

	return (
		<>
			<div className={styles.container} ref={elementRef}>
				<ToastContainer />
				<div className={styles.navbar}>
					{!isOpen && !isEditOpen && !isSpecialOpen && (
						<div className={styles.backBtn}>
							<ArrowBackIcon
								fontSize="large"
								style={{ marginLeft: "-5px", cursor: "pointer" }}
								onClick={handleBackClick}
							/>
						</div>
					)}
					<div className={styles.title}>Business Hours</div>
				</div>
				<div className={styles.storeSection}>
					<div className={styles.regularHours}>
						<div className={styles.regularHeader}>
							<div className={styles.regularTitle}>Regular Business Hours</div>
							<div style={{ fontSize: "16px", fontWeight: 500 }}>
								*These are the hours your business is available
							</div>
						</div>
						<div className={styles.timeSelect}>
							<div className={styles.timeSelectTitle}>
								<div style={{ fontSize: "17px", fontWeight: 800 }}>All Day</div>
								<button className={styles.editBtn} onClick={toggleEditHours}>
									{regularHours?.length > 0 ? "Edit" : "Add"}
								</button>
							</div>
							{regularLoaded ? (
								<div>
									<table id={styles.timeTable}>
										<tbody>
											{regularHours?.length > 0 &&
												weekDays?.map((day, index) => (
													<tr key={index}>
														<td>{day}</td>
														<td>{matchDay(index)}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							) : (
								<div id={styles.clipLoader}>
									<ClipLoader color="#000" size={50} />
								</div>
							)}
						</div>
					</div>
					<div className={styles.specialHours}>
						<div className={styles.specialHeader}>
							<div className={styles.specialHourTitle}>Special Hours</div>
							<div style={{ fontSize: "16px", fontWeight: 500 }}>
								*This will temporarily replace your regular menu hours
							</div>
						</div>
						<div className={styles.specialSelectTitle}>
							<div style={{ fontSize: "17px", fontWeight:800 }}>Dates</div>
							<button className={styles.addBtn} onClick={toggleSpecialHours}>
								Add New
							</button>
						</div>
						{specialLoaded ? (
							<table id={styles.specialTable}>
								<tbody>
									{specialHours?.map((slot, index) => (
										<tr key={index}>
											<td id={styles.specialDate}>
												{getDateString(slot?.date)}
											</td>
											{slot?.openAllDay && <td id={styles.specialTime}>Open All Day</td>}
											{slot?.closedAllDay && (
												<td id={styles.specialTime}>Closed All Day</td>
											)}
											{!slot?.openAllDay && !slot?.closedAllDay && (
												<td id={styles.specialTime}>
													{getHoursSmall(slot?.openTime)} -{" "}
													{getHoursSmall(slot?.closeTime)}
												</td>
											)}
											<td id={styles.specialBtn}>
												<button
													className={styles.dynamicBtn}
													onClick={() => {
														toggleEditSpecialHours(slot);
													}}
												>
													Edit
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div id={styles.clipLoader}>
								<ClipLoader color="#000" size={50} />
							</div>
						)}
					</div>
				</div>
				{isOpen && (
					<EditModal
						{...editProps}
						getHours={getHours}
						getWeekDay={getWeekDay}
						toggleEditHours={toggleEditHours}
					/>
				)}
				{isSpecialOpen && (
					<NewSpecialModal
						{...specialProps}
						getHours={getHours}
						getWeekDay={getWeekDay}
						sortDays={sortDays}
						toggleSpecialHours={toggleSpecialHours}
					/>
				)}
				{isEditOpen && (
					<EditSpecialModal
						{...specialProps}
						getHours={getHours}
						getWeekDay={getWeekDay}
						timeSlot={timeSlot}
						sortDays={sortDays}
						toggleEditSpecialHours={toggleEditSpecialHours}
					/>
				)}
			</div>
		</>
	);
}
