import React, { useState } from "react";
import {
	Routes,
	Route,
	Navigate,
	BrowserRouter as Router,
	useLocation,
} from "react-router-dom";

import StoreHours from "./components/screens/StoreHours/StoreHours";
import ProfileSettings from "./components/screens/ProfileSettings/ProfileSettings";
import Signup from "./components/screens/Auth/Signup/Signup";
import Signin from "./components/screens/Auth/Signin/Signin";
import ResetPassword from "./components/screens/ResetPassword/ResetPassword";
import ForgotPassword from "./components/screens/ForgotPassword/ForgotPassword";
import InventoryManagement from "./components/screens/InventoryManagement/InventoryManagement";
import Layout from "./components/OrderManagement/Layout";
import Settings from "./components/screens/Settings/Settings";
import OrderHistory from "./components/OrderManagement/OrderHistory/OrderHistory";
import Dashboard from "./components/screens/Dashboard/Dashboard";
import PaymentHistory from "./components/screens/PaymentHistory/PaymentHistory";
import NewOrderNotificationModal from "./components/NewOrderNotificationModal/NewOrderNotificationModal";
import PrivateRoutes from "./utils/PrivateRoutes";
import AuthRoutes from "./utils/AuthRoutes";

function App() {
	const handleReloadPage = () => {
		window.location.reload();
	};
	return (
		<>
			<Router>
				<NewOrderNotificationModal handleReloadPage={handleReloadPage}/>
				<Routes>
					<Route element={<PrivateRoutes />}>
						<Route exact path="/" element={<Layout  handleReloadPage={handleReloadPage}/>} />
						<Route exact path="/order-history" element={<OrderHistory handleReloadPage={handleReloadPage}/>} />
						
						<Route exact path="/settings" element={<Settings />} />

						<Route
							exact
							path="/settings/business-hours"
							element={<StoreHours />}
						/>
						<Route
							exact
							path="/settings/profile-settings"
							element={<ProfileSettings />}
						/>
						<Route exact path="/settings/dashboard" element={<Dashboard />} />

						<Route
							exact
							path="/settings/inventory-management"
							element={<InventoryManagement />}
						/>
						<Route
							exact
							path="/settings/payment-history"
							element={<PaymentHistory />}
						/>
						<Route
							exact
							path="/settings/payout-history"
							element={<PaymentHistory />}
						/>
					</Route>
					<Route element={<AuthRoutes />}>
						<Route exact path="/signup" element={<Signup />} />
						<Route exact path="/login" element={<Signin />} />
						<Route
							exact
							path="/reset-password/:tokenId?/:email?"
							element={<ResetPassword />}
						/>
						<Route exact path="/forgot-password" element={<ForgotPassword />} />
					</Route>

					<Route exact path="*" element={<Navigate to="/" />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
