import React from 'react'
import styles from './DeleteModal.module.css'

export default function DeleteModal({setIsDeleteOpen, handleDelete }) {
  return (
    <div>
        <div className={styles.modalOverlay}>
            <div className={styles.deleteModal}>
                <p>Are you sure you want to delete this special hour?</p>
                <div className={styles.btnContainer}>
                <button
                    className={styles.btnStyle}
                    onClick={() => {
                        setIsDeleteOpen(false)
                    }}
                >
                    Cancel
                </button>
                <button
                    className={styles.btnStyle}
                    onClick={() => {
                        handleDelete()
                        setIsDeleteOpen(false)
                    }}
                >
                    Confirm
                </button>
            </div>
            </div>
        </div>
    </div>
  )
}
