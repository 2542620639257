import axios from "axios";
const isProd = false; // true: prod data, false: dev data
export const v = "1.0.0";
const prodUrl =
	"https://us-central1-yourgestureapp.cloudfunctions.net/merchants/v1";
const devUrl =
	"https://us-central1-gesture-dev.cloudfunctions.net/merchants/v1";

const URL = isProd ? prodUrl : devUrl;
// Called during the process of Registration
// sends User information to create an Account

export const getUserUrl = `${URL}/users`;
export const paymentsSetupUrl = `${URL}/users/banking_stripe/link`;
export const getAnalyticsUrl = `${URL}/users/statistics`;
export const createNewUser = `${URL}/users/signup`;
export const loginUser = `${URL}/users/login`;
export const resetPassword = `${URL}/users/reset_password/password`;
export const forgotPassword = `${URL}/users/reset_password/link`;
export const updateRegularHours = `${URL}/users/hours/regular`;
export const updateSpecialHour = `${URL}/users/hours/special`;
export const deleteSpecialHour = `${URL}/users/hours/special/`;
export const updateInventory = `${URL}/users/inventory`;
export const getDenialOptions = `${URL}/tasks/denial_options`;
export const getFilteredTasks = `${URL}/tasks/active?statusFilter=`;
export const getTaskDetails = `${URL}/tasks/single/`;
export const updateTask = `${URL}/tasks/`;
export const getPaymentHistory = `${URL}/api/payment_history?date=`;
export const getPaymentDetailsForPayment = `${URL}/api/payment_history?single=`;
export const getAllTasks = `${URL}/tasks/search`;
export const getNewTasksUnacknowledged = `${URL}/tasks/unacknowledged`;
export const postNewTasksAcknowledged = `${URL}/tasks/acknowledged`;
export const getAllPayments = `${URL}/users/transfer_history`;
export const getPaymentDetails = `${URL}/users/transfer_history/single/`;
export const getAllPayout = `${URL}/users/payout_history`;
export const getPayoutDetails = `${URL}/users/payout_history/single/`;
export const getAgreementsURL = `${URL}/agreements`;