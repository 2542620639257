import { Navigate, Outlet } from "react-router-dom";
import { useStore } from "react-redux";
const AuthRoutes = () => {
	const store = useStore();

	let auth = store?.getState()?.user?.isLogged;
	return !auth ? <Outlet /> : <Navigate to="/" />;
};

export default AuthRoutes;
