import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import { createNewUser, getAgreementsURL } from "../../../../env/APIManager.js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import validator from "validator";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { useDispatch } from "react-redux";
import { CREATED_AT, LOG_USER } from "../../../../store/user.js";
import { useFetch } from "../../../../useFetch";

const NUMERIC_DASH_REGEX = /^[0-9]+$/;
const theme = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          font-size:0.1em;
          }
      `,
		},
	},
});
function Signup() {
	const dispatch = useDispatch();
	const [FName, setFName] = useState("");
	const [LName, setLName] = useState("");
	const [email, setEmail] = useState("");
	const [agreements, setAgreements] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const PNF = PhoneNumberFormat;
	const phoneUtil = PhoneNumberUtil.getInstance();

	const navigator = useNavigate();

	const { fetchFuntion, fetchLoading } = useFetch();

	const handleSignUp = async () => {
		let valid = true;

		if (FName?.trim()?.length < 1) {
			toast.error("First Name is needed.", { theme: "colored" });
			valid = false;
			return
		}
		if (LName?.trim()?.length < 1) {
			toast.error("Last Name is needed.", { theme: "colored" });
			valid = false;
			return
		}
		if (businessName?.trim()?.length < 1) {
			toast.error("Business Name is needed.", { theme: "colored" });
			valid = false;
			return
		}
		if (phoneNumber?.length < 5) {
			toast.error("Please verify that the number you have entered is valid and try again", { theme: "colored" });
			valid = false;
			return
		}
		const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
		if (password?.length < 6) {
			toast.error("password is needs to be 6 characters long.", {
				theme: "colored",
			});
			valid = false;
			return
		}
		if (!validator.isEmail(email)) {
			toast.error("Email needs to be in a valid format.", { theme: "colored" });
			valid = false;
			return
		}
		if (!phoneUtil.isValidNumber(parsedNumber)) {
			toast.error(
				"Please verify that the number you have entered is valid and try again",
				{
					theme: "colored",
				}
			);
			valid = false;
			return
		}
		if (valid) {
			let obj = {
				email: email?.trim(),
				businessName: businessName?.trim(),
				phoneNumber: phoneUtil.format(parsedNumber, PNF.E164),
				password: password?.trim(),
				firstName: FName?.trim(),
				lastName: LName?.trim(),
			};

			const res = await fetchFuntion({
				url: createNewUser,
				method: "POST",
				data: obj,
			});
			if (res?.code === "SUCCESS") {
				localStorage.setItem("token", JSON.stringify(res?.user?.token));
				dispatch(LOG_USER(true));
				dispatch(CREATED_AT(res?.user?.createdAt));
				navigator("/");
			}
		}

	};
	const onChangeFName = (e) => {
		setFName(e.target.value);
	};
	const onChangeLName = (e) => {
		setLName(e.target.value);
	};
	const onChangeBusinessName = (e) => {
		setBusinessName(e.target.value);
	};
	const onChangeEmail = (e) => {
		setEmail(e.target.value.trim());
	};
	const onChangePhoneNumber = (e) => {
		setPhoneNumber(e.target.value.trim());
	};
	const onChangePassword = (e) => {
		setPassword(e.target.value.trim());
	};
	const loginOnClickHandler = (e) => {
		navigator("../login");
	};

	useEffect(() => { getAgreements() }, []);

	async function getAgreements() {
		const data = await fetchFuntion({
			url: getAgreementsURL,
			method: "GET",
			auth:true
		}).catch(console.log);

		let { description, links, target='_blank' } = data?.data || {};

		if (!description) {
			return;
		}

		links?.forEach?.(link => {
			description = description.replace(link?.label, `<a href="${link?.url}" target="${link.target || target}">${link?.label}</a>`);
		});

		setAgreements(description);
	}

	return (
		<>
			<ToastContainer />
			{fetchLoading ? (
				<div className={styles["loading"]}>
					<ClipLoader size={100} color="#000" />
				</div>
			) : (
				<div className={styles["Main-Container"]}>

					<div className={styles["Body-Container"]}>
						<div className={styles["title"]}>
							<h1 className={styles["title-h1"]}>Sign Up</h1>
						</div>
						<ThemeProvider theme={theme}>
							<div className={styles["Body-Container-Wrap"]}>
								<div className={styles["Body-Container-Wrap-StoreName-input"]}>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>First Name</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`First Name`}
										value={FName}
										maxLength="50"
										variant="standard"
										type={"alpha"}
										size="small"
										name="FName"
										onChange={onChangeFName}
									// onKeyDown={(event) => {
									// 	if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
									// 		event.preventDefault();
									// 	}
									// }} // helperText={"Password Required"}
									/>
								</div>
								<div className={styles["Body-Container-Wrap-StoreName-input"]}>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>Last Name</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`Last Name`}
										value={LName}
										maxLength="50"
										variant="standard"
										type={"alpha"}
										name="LName"
										onChange={onChangeLName}
									/>
								</div>
								<div className={styles["Body-Container-Wrap-StoreName-input"]}>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>Business Name</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`Business Name`}
										value={businessName}
										maxLength="50"
										variant="standard"
										type={"alpha"}
										name="businessName"
										onChange={onChangeBusinessName}
									/>
								</div>
								<div
									className={styles["Body-Container-Wrap-PhoneNumber-input"]}
								>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>Phone Number</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`Phone Number`}
										value={phoneNumber}
										maxLength="50"
										variant="standard"
										type={"alpha"}
										name="phoneNumber"
										onChange={onChangePhoneNumber}
										onKeyDown={(event) => {
											if (
												(!NUMERIC_DASH_REGEX.test(event.key) ||
													phoneNumber?.length > 9) &&
												event.key !== "Backspace" &&
												event.key !== "Delete" &&
												event.key !== "ArrowLeft" &&
												event.key !== "ArrowRight" &&
												event.key !== "ArrowUp" &&
												event.key !== "ArrowDown"
											) {
												event.preventDefault();
											}
										}}
									/>
								</div>
								<div className={styles["Body-Container-Wrap-Email-input"]}>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>Email</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`Email`}
										value={email}
										maxLength="50"
										variant="standard"
										type={"alpha"}
										name="email"
										onChange={onChangeEmail}
									/>
								</div>

								<div className={styles["Body-Container-Wrap-Password-input"]}>
									<div className={styles["Body-Container-Wrap-label-div"]}>
										<span>Password</span>
										<span>Required</span>
									</div>
									<input
										className={styles["Body-Container-Wrap-input-nonname"]}
										required
										id="standard-text"
										label={`Password`}
										value={password}
										maxLength="50"
										variant="standard"
										type={"password"}
										name="password"
										onChange={onChangePassword}
									/>
								</div>

								<button
									className={styles["signup-button"]}
									onClick={handleSignUp}
								>
									Sign Up
								</button>
								<div className={styles["policy-container"]} dangerouslySetInnerHTML={{ __html: agreements }}>
								</div>
							</div>
							<div className={styles["text-to-login"]}>
								<span className={styles["text-to-login-text"]}>
									Already have an account?
								</span>
								<span
									className={styles["text-to-login-button"]}
									onClick={loginOnClickHandler}
								>
									LOG IN
								</span>
							</div>
						</ThemeProvider>
					</div>
				</div>
			)}
		</>
	);
}

export default Signup;
