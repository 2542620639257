import React, { useEffect, useState } from "react";
import styles from "./OrderDetails.module.css";
import CloseIcon from "@mui/icons-material/Close";
import OptionsModal from "../OptionsModal/OptionsModal";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { updateTask, getTaskDetails } from "../../../../env/APIManager";
import { useDispatch } from "react-redux";
import { TRIGGER_RELOAD_API } from "../../../../store/app";

import { useFetch } from "../../../../useFetch";

export default function OrderDetails({
	handleClose,
	handleStatus,
	taskId,
	handleReloadPage,
}) {
	const [deliveryMsg, setDeliveryMsg] = useState(null);
	const [readableDate, setReadableDate] = useState(null);
	const [isOptionOpen, setIsOptionOpen] = useState(false);
	const [loadingReady, setLoadingReady] = useState(false);
	const [loadingConfirm, setLoadingConfirm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [task, setTask] = useState([]);
	const dispatch = useDispatch();

	let arg = {
		url: getTaskDetails + taskId,
		method: "GET",
		auth: true,
	};
	const { result, fetchFuntion, fetchLoading } = useFetch(arg);

	useEffect(() => {
		const getTasks = async () => {
			setLoading(true);

			// const taskList = await getTaskDetails(token, taskId);

			if (result?.code === "SUCCESS") {
				setTask(result?.data?.task);
			}
			setLoading(false);
		};
		getTasks();
	}, [result]);

	const handleCancel = () => {
		setIsOptionOpen(true);
	};

	const handleGoBack = () => {
		setIsOptionOpen(false);
	};

	const handleConfirm = async (task) => {
		setLoadingConfirm(true);
		const answer = {
			status: "confirmed",
			time: Date.now(),
		};
		let arg = {
			url: updateTask + `${task?.id}/answer`,
			method: "POST",
			data: {
				answer: answer,
			},
			auth: true,
		};

		const response = await fetchFuntion(arg);
		// updateTask(token, task?.orderId, answer).then((response) => {
		if (response?.code === "SUCCESS") {
			dispatch(TRIGGER_RELOAD_API(true));
			toast.success("Order has been updated!");

			handleClose();
		}
		setLoadingConfirm(false);
		// });
	};

	const handleReady = async (task) => {
		setLoadingReady(true);
		const answer = {
			status: "ready",
			time: Date.now(),
		};

		let arg = {
			url: updateTask + `${task?.id}/answer`,
			method: "POST",
			data: {
				answer: answer,
			},
			auth: true,
		};

		const response = await fetchFuntion(arg);
		// updateTask(token, task?.orderId, answer).then((response) => {
		if (response?.code === "SUCCESS") {
			dispatch(TRIGGER_RELOAD_API(true));
			toast.success("Order has been updated!");

			handleClose();
		}
		setLoadingReady(false);
		// });
	};

	useEffect(() => {
		let currentStatus = handleStatus(task);
		setDeliveryMsg(currentStatus?.msg);
		setReadableDate(currentStatus?.readableDate);
	});
	return (
		<>
			{isOptionOpen ? (
				<OptionsModal
					task={task}
					handleGoBack={handleGoBack}
					handleClose={handleClose}
				/>
			) : (
				<div className={styles.mainContainer}>
					<div className={styles.innerContainer}>
						<div className={styles.headerContainer}>
							<CloseIcon
								onClick={handleClose}
								fontSize="large"
								sx={{ color: "black" }}
								style={{ marginLeft: "-5px", cursor: "pointer" }}
							/>
							{!loading || !fetchLoading ? (
								<div className={styles.innerHeaderContainer}>
									<p className={styles.title}>
										#{task?.orderId?.substring(task?.orderId?.length - 5)}
									</p>
									{task?.status === "new" && (
										<button className={styles.cancelBtn} onClick={handleCancel}>
											Cancel
										</button>
									)}
								</div>
							) : null}
						</div>
						{loading || fetchLoading ? (
							<div className={styles.clipLoader}>
								<ClipLoader color="#000" size={100} />
							</div>
						) : (
							<div className={styles.mainContentContainer}>
								<div className={styles.pickupContainer}>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div style={{ display: "flex", flexDirection: "row" }}>
											<p>{deliveryMsg}</p>
											<p style={{ paddingLeft: "5px" }}>{readableDate}</p>
										</div>
										<div>
											{task?.status === "cancelled" && task?.cancelReason
												? "Reason: " + task?.cancelReason
												: null}
										</div>
									</div>
								</div>
								<div className={styles.customerContainer}>
									<div id={styles.customer}>
										<p>Customer</p>
										<p style={{ fontWeight: 600, fontSize: "20px" }}>
											{task?.recipientName}
										</p>
									</div>
									<div id={styles.product}>
										<div className={styles.receiptHeaders}>
											<p>Product</p>
											<p>Sub Total</p>
										</div>
										<div className={styles.receiptValues}>
											<p>{task?.skuName}</p>
											<p>${task?.subTotal}</p>
										</div>
									</div>
									<div id={styles.total}>
										<div className={styles.totalItem}>
											<p style={{ textDecoration: "underline" }}>Total</p>
											<p style={{ fontSize: "20px" }}>${task?.total}</p>
										</div>
									</div>
								</div>
								<div className={styles.btnContainer}>
									{task?.status === "new" && (
										<>
											<button
												className={styles.readyBtn}
												onClick={() => handleReady(task)}
											>
												Ready For Pickup{" "}
												{loadingReady && (
													<span className={styles.btnLoader}>
														<ClipLoader color="#000" size={30} />
													</span>
												)}
											</button>
											<button
												className={styles.confirmBtn}
												onClick={() => handleConfirm(task)}
											>
												Confirm and Prepare{" "}
												{loadingConfirm && (
													<span className={styles.btnLoader}>
														<ClipLoader color="#FFF" size={30} />
													</span>
												)}
											</button>
										</>
									)}
									{task?.status === "confirmed" && (
										<button
											className={styles.confirmBtn}
											onClick={() => handleReady(task)}
										>
											Ready For Pickup{" "}
											{loadingConfirm && (
												<span className={styles.btnLoader}>
													<ClipLoader color="#FFF" size={30} />
												</span>
											)}
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
