import React from "react";
import Sidebar from "./Sidebar";
import ActiveOrders from "./ActiveOrders/ActiveOrders";

import styles from "./Layout.module.css";


function Layout({ handleReloadPage}) {
	return (
		<div className={styles.mainContainer}>
			<Sidebar />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					flex: 0.8,
					backgroundColor: "#eee",
				}}
			>
				<ActiveOrders handleReloadPage={handleReloadPage}/>
			</div>
		</div>
	);
}

export default Layout;
