import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllPayments,getAllPayout } from '../../../env/APIManager'
import FilterSelect from './FilterSelect/FilterSelect';
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import styles from './PaymentHistory.module.css'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentDetails from './PaymentDetails/PaymentDetails';
import PayoutDetails from './PayoutDetails/PayoutDetails';
import { useFetch } from "../../../useFetch";
import DataTable from './DataTable/DataTable';
import PaymentRow from './PaymentRow/PaymentRow';
import PayoutRow from './PayoutRow/PayoutRow';


const PaymentHistory = () => {
  const navigate = useNavigate()
  
  // Retrieve the user's createdAt value from the userInfo store
  const createdAt = useSelector(state => state.user.createdAt);

  // Define state for the payment history data and filter options
  const [data, setData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false)
  const [selectedPaymentId, setSelectedPaymentId] = useState('')
  // Define state for the currently selected filter option
  const [selectedFilter, setSelectedFilter] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear(), });
  // Define state for current payment-history Data navigation
  const [historySelected, setHistorySelected] = useState("payment");
  let currentScroll = {x:0,y:0};
  const dataMap = {
    "payment":
    {
      transferId: "Payment Id",
      createdTime: "Created",
      total: "Total",
      taskId: "Task Id",
      status: "Status",
    },
    "payout": { 
      payoutId:"Payout Id",
      amount:"Amount", 
      scheduled:"Scheduled",
      status:"Status",
  }
}
  const historySelectedComponent = { 
    row: { "payment": PaymentRow, "payout": PayoutRow },
    details: { "payment": PaymentDetails, "payout": PayoutDetails }, 
    url: { "payment": getAllPayments, "payout": getAllPayout } 
  }

  let arg = {
    url: historySelectedComponent.url[historySelected]
    + `?date=${new Date(
     selectedFilter.month + "/01/" + selectedFilter.year
    ).getTime()}`
    ,
    method: "GET",
    auth: true
  };

  const { result, loading, fetchFuntion,fetchLoading } = useFetch(arg);

  useEffect(() => {
    
    if (result?.code === "SUCCESS") {
      setData(result?.data)
    }
    else {
      setData([])
    }
    
  }, [result, selectedFilter]);

  const handleFetchData = async () => {
    await fetchFuntion(arg)
  }
  useEffect(() => {
    handleFetchData()
    setData([])
  }, [selectedFilter])
  useEffect(() => {
    handleFetchData()
    setData([])
  }, [historySelected])
  // Calculate the filter options based on the user's account creation date
  useEffect(() => {
    handleSetFilterOptions();
  }, [createdAt]);
  const handleSetFilterOptions = () => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const startDate = new Date(createdAt);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const filterOptions = [];


    for (let year = startYear; year <= currentYear; year++) {
      const start = (year === startYear) ? startMonth : 1;
      const end = (year === currentYear) ? currentMonth : 12;
      const months = []
      for (let month = start; month <= end; month++) {
        months.push(month);
      }
      filterOptions.push({ year, months });
    }
    setFilterOptions(filterOptions);
  }
  const handleHistoryNavigation = (e) => {
    // const [fetchResult, fetchLoading] = fetchFunction(arg)

    setHistorySelected(e.target.id)
  }
  const handleFilterInventory = (filter) => {

    setSelectedFilter({ year: filter.year, month: filter.month })
    
  };
  const handleOpenModal = (Id) => {
    setSelectedPaymentId(Id)
    document.body.style.overflow = 'hidden';
    currentScroll={x:window.scrollX,y:window.scrollY}
    window.scrollTo(0,0) 
    setShowDetails(true)
  }
  const handleCloseModal = () => {
    document.body.style.overflow = 'auto';
    window.scrollTo(currentScroll.x,currentScroll.y)
    setShowDetails(false)
  }

  function goToPaymentId(id) {
    setHistorySelected("payment");
    setSelectedPaymentId(id);
  }

  const renderSelectedComponent = () => {
    const DetailComponent = historySelectedComponent['details'][historySelected];
    if (!DetailComponent) return null; // or some fallback UI

    return (
      <DetailComponent paymentId={selectedPaymentId} handleCloseModal={handleCloseModal} goToPaymentId={goToPaymentId}/>
    );
  };
  return <div className={styles.container}>
    {
      (loading ||fetchLoading) ?
        <div className={styles["spinner"]}>
          <ClipLoader size={100} color="#8585ff" />
        </div>
      :
      <div className={styles["main-container"]}>
        {showDetails && renderSelectedComponent()}
        <div className={styles["main_heading"]}>
          <div className={styles["back-Arrow"]} onClick={() => { navigate('/settings') }}><ArrowBackIcon fontSize="large" /></div>
          <h1 className={styles.title}>Payment History</h1>
          <div className={styles["space"]}></div>
          <div className={styles["payment-history-navigation"]}>
            <div id="payment" onClick={handleHistoryNavigation} className={styles[historySelected === "payment" ? 'payment-history-navigation-button-active' : 'payment-history-navigation-button']}>Payments</div>
            <div id="payout" onClick={handleHistoryNavigation} className={styles[historySelected === "payout" ? 'payment-history-navigation-button-active' : 'payment-history-navigation-button']}>Payouts</div>
          </div>
        </div>
        <div className={styles["div-wrapper-body"]}>
          <FilterSelect setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter} filterOptions={filterOptions} handleFilterInventory={handleFilterInventory} />
          {data?.length>0 && <div className={styles["table-header-container"]}>{Object.keys(dataMap[historySelected]).map((key, index) => {
            return <div key={index} className={styles[historySelected==="payment"?"table-header-payment":historySelected === "payout"?"table-header-payout":""]}>{dataMap[historySelected][key]}</div>
          }
          )}
            <div className={styles["table-header"]}></div>
          </div>}

          <DataTable data={data} handleOpenModal={handleOpenModal} RowComponent={historySelectedComponent['row'][historySelected]} keys={Object.keys(dataMap[historySelected])} />
        </div>
      </div>
    }
  </div>;
};

export default PaymentHistory;