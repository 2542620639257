import { useState, useEffect } from 'react'
import styles from './PaymentDetails.module.css'
import { getPaymentDetails } from '../../../../env/APIManager';
import { Divider } from '@mui/material';
import { ClipLoader } from 'react-spinners'
import CloseIcon from '@mui/icons-material/Close';
import { useFetch } from '../../../../useFetch';
export default function PaymentDetails({ paymentId, handleCloseModal }) {
    const handleOnClickCloseButton = (e) => {
        handleCloseModal()
    }
    const [payment, setPayment] = useState({})
    const [notFound, setNotFound] = useState({})


    let arg = {
        url: getPaymentDetails + paymentId,
        method: "GET",
        auth: true
    };
    const { result, loading } = useFetch(arg);
    useEffect(() => {
        if (result?.code === 'SUCCESS') {
            setPayment(result?.data);
            setNotFound(false);
            return;
        }

        setNotFound(true);
    }, [result])
    return (

        <div className={styles["Modal-container"]}>
            <div className={styles["close-button"]} onClick={handleOnClickCloseButton}><CloseIcon /></div>
            <div className={styles["modal-body"]}>
            <div className={styles["Header-modal"]}>
                
                <span className={styles["header-modal-paymentid"]}>#{paymentId?.substring(paymentId?.length-5)}</span>
                
            </div>

            {loading ? (
                <div className={styles["spinner"]}>
                    <ClipLoader size={100} color="#8585ff" />
                </div>
            ) : <>

                {!notFound && <>
                <div className={styles["modal-status"]}>
                    <span className={styles["modal-payment-status"]}>Status: {payment?.status}</span>
                </div>
                <div className={styles["body-box-container"]}>
                    <div className={styles["left-box-container"]}>
                        <div className={styles["left-top-box"]}>
                            <span style={{ fontWeight: 700, marginBottom: "20px" }}>Payment Date</span>
                            <span>{new Date(payment?.createdTime).toLocaleDateString("en-US")}</span>
                        </div>
                        <div className={styles['left-bot-box']}>
                            <span style={{ fontWeight: 700, marginBottom: "20px" }}>Order</span>
                            <span style = {{ fontWeight: 500 , textDecoration:"underline" }}  >{payment?.orderId}</span>
                        </div>
                    </div>
                    <div className={styles["right-box-container"]}>
                        <div className={styles["right-box"]}>
                            <span className={styles["right-box-header"]}>Payment Details</span>
                            <div className={styles["right-box-body"]}>

                            <div className={styles["right-box-divider-divider"]}>
                                <Divider light style={{ border: "1px solid #000000" }} />
                            </div>
                            {payment && <div className={styles["right-box-divider"]}>
                                <span>Amount:</span>
                                <span>${payment?.subTotal?payment?.subTotal:payment?.total}</span>
                            </div>}

                            {payment?.tax > 0 && <div className={styles["right-box-divider"]}>
                                <span>Tax:</span>
                                <span>${payment?.tax}</span>
                            </div>}

                            {payment?.commission > 0 && <div className={styles["right-box-divider"]}>
                                <span>Commission:</span>
                                <span>${payment?.commission}</span>
                            </div>}

                            {payment?.adjustments > 0 && <div className={styles["right-box-divider"]}>
                                <span>Adjustments:</span>
                                <span>${payment?.adjustments}</span>
                            </div>}
                            <div className={styles["right-box-divider-divider"]}>
                                <Divider light={false} style={{ border: "1px solid #000000" }} />
                            </div>
                            </div>

                            <div className={styles["right-box-divider-amount"]}>
                                <span style={{ fontFamily: "Mulish", fontWeight: 700 }}>Payment Amount:</span>
                                <span>${payment?.total}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </>}
            </>}

            {notFound && <div className={styles["not-found"]}>
                <span>Payment not found</span>
            </div>}

            </div>
        </div>

    )
}