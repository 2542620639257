import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./FilterSelect.css";

const FilterSelect = ({ handleFilterSelection ,optionsList,currentOption}) => {
	const [isOptionsOpen, setIsOptionsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(0);
	

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	const setSelectedThenCloseDropdown = (index) => {
		setSelectedOption(index);
		handleFilterSelection(index);
		setIsOptionsOpen(false);
	};

	const handleKeyDown = (index) => (e) => {
		switch (e.key) {
			case " ":
			case "SpaceBar":
			case "Enter":
				e.preventDefault();
				setSelectedThenCloseDropdown(index);
				break;
			default:
				break;
		}
	};

	return (
		<div className={"wrapper"}>
			<div className={"container"}>
				<button
					id="filter-button"
					type="button"
					aria-haspopup="listbox"
					aria-expanded={isOptionsOpen}
					className={isOptionsOpen ? "expanded" : ""}
					onClick={toggleOptions}
				>
					<div className="option-button-text">
						<span className="option-text">{currentOption}</span>
						{isOptionsOpen ? (
							<KeyboardArrowUpIcon viewBox="0 0 23 23" fontSize="small" />
						) : (
							<ExpandMoreIcon viewBox="0 0 23 23" fontSize="small" />
						)}
					</div>
				</button>
				<ul
					className={`options ul-offset-show-${selectedOption} ${
						isOptionsOpen ? "show" : ""
					}`}
					role="listbox"
					aria-activedescendant={currentOption}
					tabIndex={-1}
				>
					<div className={"filter-text-option"}>
						<span>Filter</span>
					</div>
					<Divider light style={{ marginBottom: "6px" }} />
					{optionsList?.map((option, index) => (
						<li
							key={index}
							id={option}
							role="option"
							aria-selected={currentOption == option}
							tabIndex={0}
							onKeyDown={handleKeyDown(index)}
							onClick={() => {
								setSelectedThenCloseDropdown(index);
							}}
						>
							{option}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default FilterSelect;
