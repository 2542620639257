import React, { useState, useEffect } from "react";
import styles from "./OptionsModal.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { TRIGGER_RELOAD_API } from "../../../../store/app";
import { getDenialOptions, updateTask } from "../../../../env/APIManager";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../../../../useFetch";

export default function OptionsModal({ task, handleGoBack, handleClose}) {
	const [denialOptions, setDenialOptions] = useState(null);
	const [checkboxState, setCheckboxState] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const dispatch = useDispatch();
	const [answer, setAnswer] = useState({
		status: "cancelled",
		cancelId: "",
		cancelReason: "",
		time: null,
	});
	let arg = {
		url: getDenialOptions,
		method: "GET",
		auth: true,
	};
	const { result, fetchFuntion, fetchLoading } = useFetch(arg);

	const handleCheckboxChange = (event, option, index) => {
		const newCheckboxState = [];
		newCheckboxState[index] = event.target.checked;
		setAnswer({
			...answer,
			cancelId: event.target.checked ? option?.cancelId : "",
			cancelReason: event.target.checked ? option?.cancelReason : "",
		});

		setCheckboxState(newCheckboxState);
	};

	const handleSubmit = async () => {
		const isMissing =
			answer?.cancelId === "" || answer?.cancelReason === "" ? true : false;

		if (!isMissing) {
			const answerWithTimestamp = answer;
			answerWithTimestamp["time"] = Date.now();
			setSubmitLoading(true);

			// ${taskId}/answer

			// method: "POST",
			// body: JSON.stringify({ answer: answer }),

			let arg = {
				url: updateTask + `${task?.id}/answer`,
				method: "POST",
				data: {
					answer: answerWithTimestamp,
				},
				auth:true
			};

			const response = await fetchFuntion(arg);

			// updateTask(token, task?.id, answerWithTimestamp).then((response) => {
			if (response?.code === "SUCCESS") {
				dispatch(TRIGGER_RELOAD_API(true));
				handleGoBack();
				handleClose();
			}
			// });
			setSubmitLoading(false);
		} else {
			toast.error("Please select an option before submitting!");
		}
	};

	useEffect(() => {
		const getOptions = async () => {
			setLoading(true);

			// let response = await getDenialOptions(token);

			if (result?.code === "SUCCESS") {
				setDenialOptions(result?.data?.options);
				setLoading(false);
			}
		};
		getOptions();
	}, [result]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.innerContainer}>
				<div className={styles.headerContainer}>
					<ArrowBackIcon
						fontSize="large"
						style={{ marginLeft: "-5px", cursor: "pointer" }}
						onClick={handleGoBack}
					/>
					<div
						className={styles.title}
					>{`Options for Denying Order #${task?.id?.substring(
						task?.id?.length - 5
					)}`}</div>
				</div>
				<div className={styles.checkboxContainer}>
					{loading ? (
						<div className={styles.clipLoader}>
							<ClipLoader color="#000" size={65} />
						</div>
					) : (
						<>
							{denialOptions?.map((option, index) => (
								<label key={index} className={styles.customCheckmark}>
									<input
										type="checkbox"
										checked={checkboxState[index]}
										onChange={(event) =>
											handleCheckboxChange(event, option, index)
										}
									/>
									<span className={styles.checkmark}></span>
									<span className={styles.label}>{option?.cancelReason}</span>
								</label>
							))}
							<button className={styles.submitBtn} onClick={handleSubmit}>
								Submit{" "}
								{submitLoading && (
									<span className={styles.btnLoader}>
										<ClipLoader color="#FFF" size={30} />
									</span>
								)}
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
