import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./ActiveOrders.module.css";
import { useSelector, useDispatch} from "react-redux";
import { TRIGGER_RELOAD_API } from "../../../store/app";
import OrderDetails from "../OrderHistory/OrderDetails/OrderDetails";
import TaskRow from "../OrderHistory/TaskRow/TaskRow";
import ClipLoader from "react-spinners/ClipLoader";
import { getFilteredTasks } from "../../../env/APIManager";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../../../useFetch";

const toastSettings = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

export default function ActiveOrders({ handleReloadPage}) {
	const filterId = useSelector((state) => state?.app?.filterId);
	const triggerReloadAPI = useSelector((state) => state?.app?.triggerReloadAPI);
	const [searchParams] = useSearchParams();
	const [tasks, setTasks] = useState([]);
	const [focusedTaskId, setFocusedTaskId] = useState(null);
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const dispatch = useDispatch();

	const handleStatus = (task) => {
		var msg;
		if (
			task?.status === "new" ||
			((task?.status === "confirmed" || task?.status === "ready") &&
				task?.grunner_name === undefined)
		) {
			msg = "Due for pick up on…";
		} else if (
			(task?.status === "confirmed" || task?.status === "ready") &&
			task?.grunner_name
		) {
			msg = `${task?.grunner_name} is on the way to pick up...`;
		} else if (task?.status === "picked") {
			msg = `${task?.grunner_name} has picked up...`;
		} else if (task?.status === "delivered") {
			msg = "Delivered on";
		} else if (task?.status === "cancelled") {
			msg = "Cancelled on";
		}

		return { msg, readableDate: task?.schedule_readable };
	};

	const handleOpen = (task) => {
		setFocusedTaskId(task?.id);
		setIsDetailOpen(true);
	};

	const handleClose = () => {
		setFocusedTaskId(null);
		setIsDetailOpen(false);
	};

	let arg = {
		url: getFilteredTasks + filterId,
		method: "GET",
		auth: true,
	};
	
	var {  fetchFuntion,fetchLoading, loading} = useFetch(arg);

	useEffect(() => {
		const getTasks = async () => {
			let taskList = await fetchFuntion(arg);

			if (taskList?.code === "SUCCESS") {
				setTasks(taskList?.data?.tasks);
				dispatch(TRIGGER_RELOAD_API(false));
			}
		};
		getTasks();
	}, [filterId,triggerReloadAPI]);

	useEffect(() => {
		const taskId = searchParams.get("taskId");
		if (!taskId) {
			return;
		}
		handleOpen({ id: taskId });
	}, []);

	return (
		<>
			<ToastContainer {...toastSettings} />
			{loading || fetchLoading? (
				<div className={styles.clipLoader}>
					<ClipLoader color="#000" size={50} />
				</div>
			) : tasks?.length <= 0 ? (
				<p style={{ padding: "15px" }}>
					There are no {filterId} orders to display.
				</p>
			) : (
				<>
					{isDetailOpen && (
						<OrderDetails
							taskId={focusedTaskId}
							handleStatus={handleStatus}
							handleClose={handleClose}
							handleReloadPage={handleReloadPage}
							
						/>
					)}
					<div className={styles.mainContentContainer}>
						{tasks?.map((task, index) => (
							<TaskRow
								task={task}
								key={index}
								handleStatus={handleStatus}
								handleOpen={handleOpen}
							/>
						))}
					</div>
				</>
			)}
		</>
	);
}
