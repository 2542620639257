import { useState } from "react"
import styles from "./ToggleSwitch.module.css";
import { updateInventory } from '../../../../env/APIManager'
import ClipLoader from "react-spinners/ClipLoader";
import { useFetch } from "../../../../useFetch";
import { update } from "lodash";

const ToggleSwitch = ({ label, handleToggle, toggle, id, index, token }) => {

  const { fetchFuntion, fetchLoading } = useFetch();

  const handleSwitchToggle = async () => {
    // const res = await getUpdateInventoryInStock(token, {id:id,inStock:!toggle})

    let arg = {
      url: updateInventory,
      method: "PATCH",
      data: {
        sku: { skuId: id, inStock: !toggle },

      },
      auth: true,
    };


    const res = await fetchFuntion(arg);


    if (res?.code === "SUCCESS")
      handleToggle(id, index, !toggle);

  };
  return (
    <div className={styles["toggle-switch-container"]}>
      {fetchLoading ? <div className={styles["spinner"]}>
        <ClipLoader size={20} color="#000" />
      </div> : <></>}
      <input
        className={styles['privacy-toggle']}
        id={'toggle-for-checkbox' + index}
        type='checkbox'
        checked={toggle}
        onChange={handleSwitchToggle}
      />

    </div>
  );
};

export default ToggleSwitch;
