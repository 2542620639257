import { useState } from "react"
import styles from "./ForgotPassword.module.css"
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../../env/APIManager.js'
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { useFetch } from "../../../useFetch";


export default function ResetPassword() {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const { fetchFuntion, fetchLoading } = useFetch();

  const resetPasswordOnClickHandler = async () => {
    // const res = await forgotPassword(email)
    if (email.length === 0) {
      toast.error("Email is required.", { theme: "colored" })
      return
    }
    else if (!validator.isEmail(email)) {
      toast.error("Please check your email and try again. Email needs to be in correct format.", { theme: "colored" })
      return
    }
    let arg = {
      url: forgotPassword,
      method: "POST",
      data: {
        email
      },
    };

    const res = await fetchFuntion(arg);


    if (res?.code === "SUCCESS") {
      toast.success(res?.message, { theme: "colored" })
    }


  }
  const loginOnClickHandler = () => {
    navigate("/login")
  }
  return (
    <>
      <ToastContainer />
      {fetchLoading ? (
        <div className={styles["loading"]}>
          <ClipLoader size={100} color="#000" />
        </div>
      ) : (
        <div className={styles["Main-Container"]}>
          <div className={styles["Body-Container"]}>
            <div className={styles["title"]}>
              <h1 className={styles["title-h1"]}>Reset Password</h1>
            </div>
            <div className={styles["Body-Container-Wrap"]}>
              <div className={styles["Body-Container-Wrap-Email-input"]}>
                <div className={styles["Body-Container-Wrap-label-div"]}><span>Email</span><span>Required</span></div>
                <input className={styles["Body-Container-Wrap-input-nonname"]}
                  required
                  id="standard-text"
                  label={`Email`}
                  value={email}
                  maxLength="50"
                  variant="standard"
                  type={"alpha"}
                  name="email"
                  // helperText={"Email Required"}
                  onChange={onChangeEmail}
                />
              </div>

              <button className={styles["updatePassword-button"]} onClick={resetPasswordOnClickHandler}>
                Update Password
              </button>

            </div>
            <div className={styles["text-to-login"]}>
              <span className={styles["text-to-login-text"]}>
                Back to{" "}
                <span className={styles["text-to-login-button"]} onClick={loginOnClickHandler}>
                  {" "}Log In
                </span>
              </span>
            </div>
          </div>
        </div>)}

    </>
  )
}


