import React, { useState } from "react";
import Chart from "./Chart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ClipLoader from "react-spinners/ClipLoader";

import { useFetch } from "../../../useFetch";
import { getAnalyticsUrl } from "../../../env/APIManager";

function Dashboard() {
	// to set filter(this month/this week)
	const [active, setActive] = useState(true);

	// to set filtered labels (this month/this week)
	const [filteredLabels, setfilteredLabels] = useState([]);

	// to set filtered revenue (this month/this week)
	const [filteredRevenue, setFilteredRevenue] = useState([]);

	// to set filtered orders (this month/this week)
	const [filteredOrders, setFilteredOrders] = useState([]);

	const [filteredOrdersExists, setFilteredOrdersExists] = useState(false);
	const [filteredRevenueExists, setFilteredRevenueExists] = useState(false);

	// to update chart after  data is filtered
	const [updateChart, setUpdateChart] = useState();

	const navigate = useNavigate();

	// we need to define these variables outside of hook to prevent custom hook rerender
	let revenue = [];
	let labels = [];
	let order = [];
	let orderExists = false;
	let revenueExists = false;

	// to get data on page load from the custom hook

	let arg = {
		url: getAnalyticsUrl,
		method: "GET",
		auth: true,
	};
	const { result, loading } = useFetch(arg);

	// set initial data to weeklyData
	let filteredData =
		result?.statistics?.weeklyData && result?.statistics?.weeklyData?.length > 0
			? result?.statistics?.weeklyData
			: result?.statistics?.monthlyData &&
			  result?.statistics?.monthlyData?.length > 0
			? result?.statistics?.monthlyData
			: [];

	// set initial labels
	labels = filteredData?.map((item) => {
		var date = new Date(item?.date);

		// Get year, month, and day part from the date
		// var year = date.toLocaleString("default", { year: "numeric" });
		// var month = date.toLocaleString("default", { month: "2-digit" });
		// var day = date.toLocaleString("default", { day: "2-digit" });

		// Generate yyyy-mm-dd date string
		// var formattedDate = year + "-" + month + "-" + day;

		const year = date.getUTCFullYear();
		const month = String(date.getUTCMonth() + 1).padStart(2, "0");
		const day = String(date.getUTCDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	});

	let getActive =
		result?.statistics?.weeklyData && result?.statistics?.weeklyData?.length > 0
			? true
			: false;

	let ordersExists =
		filteredData?.filter(function(o) {
			return o.hasOwnProperty("orders");
		}).length > 0;

	let revenuesExists =
		filteredData?.filter(function(o) {
			return o.hasOwnProperty("revenue");
		}).length > 0;

	// set initial order
	if (ordersExists) {
		order = filteredData?.map((item) => item?.orders);
		orderExists = true;
	} else {
		orderExists = false;
	}

	// set initial revenue
	if (revenuesExists) {
		revenue = filteredData?.map((item) => item?.revenue);
		revenueExists = true;
	} else {
		revenueExists = false;
	}

	// const date = new Date(timestamp)
	const handleGoBack = () => {
		navigate("/settings");
	};

	const setThisMonth = () => {
		if (
			result?.statistics?.monthlyData &&
			result?.statistics?.monthlyData?.length > 0
		) {
			filteredData = result?.statistics?.monthlyData;
			// revenue = filteredData?.map((item) => item?.revenue);
			labels = filteredData?.map((item) => {
				var date = new Date(item?.date);

				// // Get year, month, and day part from the date
				// var year = date.toLocaleString("default", { year: "numeric" });
				// var month = date.toLocaleString("default", { month: "2-digit" });
				// var day = date.toLocaleString("default", { day: "2-digit" });

				// // Generate yyyy-mm-dd date string
				// var formattedDate = year + "-" + month + "-" + day;

				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, "0");
				const day = String(date.getUTCDate()).padStart(2, "0");
				const formattedDate = `${year}-${month}-${day}`;
				return formattedDate;
			});

			let ordersExists =
				filteredData?.filter(function(o) {
					return o.hasOwnProperty("orders");
				}).length > 0;

			let revenuesExists =
				filteredData?.filter(function(o) {
					return o.hasOwnProperty("revenue");
				}).length > 0;

			if (ordersExists) {
				order = filteredData?.map((item) => item?.orders);
				setFilteredOrdersExists(true);
			} else {
				setFilteredOrdersExists(false);
			}

			if (revenuesExists) {
				revenue = filteredData?.map((item) => item?.revenue);
				setFilteredRevenueExists(true);
			} else {
				setFilteredRevenueExists(false);
			}

			setActive(false);
			setfilteredLabels(labels);
			setFilteredRevenue(revenue);
			setFilteredOrders(order ? order : []);
			setUpdateChart(Math.random());
		} else {
			toast.info(
				"Sorry, there is no available data to display. Please check back later.",
				{ theme: "colored" }
			);
		}
	};
	const setThisWeek = () => {
		if (
			result?.statistics?.weeklyData &&
			result?.statistics?.weeklyData?.length > 0
		) {
			filteredData =
				result?.statistics?.weeklyData &&
				result?.statistics?.weeklyData?.length > 0
					? result?.statistics?.weeklyData
					: [];

			let ordersExists =
				filteredData?.filter(function(o) {
					return o.hasOwnProperty("orders");
				}).length > 0;

			let revenuesExists =
				filteredData?.filter(function(o) {
					return o.hasOwnProperty("revenue");
				}).length > 0;

			if (ordersExists) {
				order = filteredData?.map((item) => item?.orders);
				setFilteredOrdersExists(true);
			} else {
				setFilteredOrdersExists(false);
			}

			if (revenuesExists) {
				revenue = filteredData?.map((item) => item?.revenue);
				setFilteredRevenueExists(true);
			} else {
				setFilteredRevenueExists(false);
			}

			labels = filteredData?.map((item) => {
				var date = new Date(item?.date);

				// // Get year, month, and day part from the date
				// var year = date.toLocaleString("default", { year: "numeric" });
				// var month = date.toLocaleString("default", { month: "2-digit" });
				// var day = date.toLocaleString("default", { day: "2-digit" });

				// // Generate yyyy-mm-dd date string
				// var formattedDate = year + "-" + month + "-" + day;
				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, "0");
				const day = String(date.getUTCDate()).padStart(2, "0");
				const formattedDate = `${year}-${month}-${day}`;

				return formattedDate;
			});
			setActive(true);
			setfilteredLabels(labels);
			setFilteredRevenue(revenue);
			setFilteredOrders(order ? order : []);
			setUpdateChart(Math.random());
		} else {
			toast.info(
				"Sorry, there is no available data to display. Please check back later.",
				{ theme: "colored" }
			);
		}
	};

	const totalRevenue = result?.statistics?.totalData?.revenue;

	const weeklyRevenue = result?.statistics?.weeklyData
		?.map((item) => item.revenue)
		?.reduce((a, b) => a + b, 0);

	const totalOrders = result?.statistics?.totalData?.orders;
	const weeklyOrders = result?.statistics?.weeklyData
		?.map((item) => item?.orders)
		?.reduce((a, b) => a + b, 0);

	const convert = (n) => {
		if (n < 1e3) return n;
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
	};
	return (
		<div className={styles.mainContainer}>
			<div className={styles.headerContainer}>
				<ArrowBackIcon
					fontSize="large"
					style={{ marginLeft: "-5px", cursor: "pointer" }}
					onClick={handleGoBack}
				/>
				<div className={styles.title}>Dashboard</div>
			</div>
			<ToastContainer />

			{loading ? (
				<div
					style={{
						with: "100vw",
						height: "80vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<ClipLoader size={100} color="#000" />
				</div>
			) : totalOrders || weeklyRevenue || weeklyOrders || totalOrders ? (
				<div className={styles.layoutContainer}>
					<div className={styles.filterContainer}>
						<div
							className={
								active && getActive
									? styles.btnContainer
									: styles.activeBtnContainer
							}
						>
							<button onClick={setThisMonth} className={styles.btn}>
								This Month
							</button>
						</div>
						<div
							className={
								active && getActive
									? styles.activeBtnContainer
									: styles.btnContainer
							}
						>
							<button onClick={setThisWeek} className={styles.btn}>
								This Week
							</button>
						</div>
					</div>
					<div className={styles.contentMainContainer}>
						<div className={styles.contentBoxes}>
							<div className={styles.contentBox}>
								<div className={styles.contentTitle}>Total Revenue</div>
								<div className={styles.totalBox}>
									${" "}
									{totalRevenue
										? totalRevenue === 0
											? convert(weeklyRevenue)
											: convert(totalRevenue)
										: weeklyRevenue
										? convert(weeklyRevenue)
										: 0}
								</div>
							</div>
							<div className={styles.contentBox}>
								<div className={styles.contentTitle}>Revenue This Week</div>
								<div className={styles.totalBox}>
									$ {weeklyRevenue ? convert(weeklyRevenue) : 0}
								</div>
							</div>
						</div>
						<div className={styles.chart}>
							<Chart
								loading={loading}
								label={filteredLabels?.length > 0 ? filteredLabels : labels}
								dataS={filteredRevenue?.length > 0 ? filteredRevenue : revenue}
								dataExists={revenueExists || filteredRevenueExists}
								updateChart={updateChart}
								text={"Revenue"}
							/>
						</div>
					</div>

					{/* orders */}

					<div className={styles.contentMainContainer}>
						<div className={styles.contentBoxes}>
							<div className={styles.contentBox}>
								<div className={styles.contentTitle}>Total Orders</div>
								<div className={styles.totalBox}>
									{totalOrders
										? totalOrders === 0
											? convert(weeklyOrders)
											: convert(totalOrders)
										: weeklyOrders
										? convert(weeklyOrders)
										: 0}
								</div>
							</div>
							<div className={styles.contentBox}>
								<div className={styles.contentTitle}>Orders This Week</div>
								<div className={styles.totalBox}>
									{weeklyOrders ? convert(weeklyOrders) : 0}
								</div>
							</div>
						</div>
						<div className={styles.chart}>
							<Chart
								loading={loading}
								label={filteredLabels?.length > 0 ? filteredLabels : labels}
								dataS={filteredOrders?.length > 0 ? filteredOrders : order}
								dataExists={orderExists || filteredOrdersExists}
								updateChart={updateChart}
								text={"Orders"}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.noContent}>
					Sorry, there is no available data to display. Please check back later.
				</div>
			)}
		</div>
	);
}

export default Dashboard;
