import React, { useEffect, useReducer, useState, useRef } from "react";
import styles from "./EditModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { updateRegularHours } from "../../../../env/APIManager";
import { useFetch } from "../../../../useFetch";

const days = [...Array(7)].map((_, i) => i);

const ACTIONS = {
	OPENTIME: "updateOpenTime",
	CLOSETIME: "updateCloseTime",
	UPDATECLOSED: "updateClosedAllDay",
	UPDATEOPEN: "updateOpenAllDay",
	INITIALSTATE: "initalizeState",
};

function reducer(state, action) {
	const weekDay = action?.payload?.dayOfWeekId;
	switch (action?.type) {
		case ACTIONS.OPENTIME:
			return {
				...state,
				[weekDay]: { ...state[weekDay], openTime: action?.payload?.time },
			};
		case ACTIONS.CLOSETIME:
			return {
				...state,
				[weekDay]: { ...state[weekDay], closeTime: action?.payload?.time },
			};
		case ACTIONS.UPDATECLOSED:
			return {
				...state,
				[weekDay]: {
					...state[weekDay],
					closedAllDay: action?.payload?.closedAllDay,
					openAllDay: false,
				},
			};
		case ACTIONS.UPDATEOPEN:
			return {
				...state,
				[weekDay]: {
					...state[weekDay],
					openAllDay: action?.payload?.openAllDay,
					closedAllDay: false,
				},
			};
		case ACTIONS.INITIALSTATE:
			return {
				...state,
				[weekDay]: {
					openTime: action?.payload?.openTime,
					closeTime: action?.payload?.closeTime,
					closedAllDay: action?.payload?.closedAllDay,
					openAllDay: action?.payload?.openAllDay,
					dayOfWeekId: weekDay,
				},
			};
	}
}

export default function EditModal({
	isOpen,
	toggleEditHours,
	regularHours,
	setRegularHours,
	getHours,
	getWeekDay,
	weekDays,
	hours,
	token,
	toastSettings,
	selectorStyle,
}) {
	const entries = {
		openTime: "",
		closeTime: "",
		closedAllDay: false,
		openAllDay: false,
	};
	const dates = {
		0: entries,
		1: entries,
		2: entries,
		3: entries,
		4: entries,
		5: entries,
		6: entries,
	};
	const [state, dispatch] = useReducer(reducer, dates);
	const [previousState, setPreviousState] = useState(null);
	const [saveLoading, setSaveLoading] = useState(false);

	const { fetchFuntion, fetchLoading } = useFetch();

	const handleSave = async () => {
		const emptyChecker = Object?.values(state)?.map(
			({ openTime, closeTime, closedAllDay, openAllDay }) => ({
				openTime,
				closeTime,
				closedAllDay,
				openAllDay,
			})
		);
		const isMissingSelection = emptyChecker?.some((value) => {
			if (!value?.closedAllDay && !value?.openAllDay) {
				if (value?.openTime === "" || value?.closeTime === "") return true;
			}
			return JSON.stringify(value) === JSON.stringify(entries);
		});

		const areStatesEqual =
			JSON.stringify(previousState) === JSON.stringify(state);
		const regularHoursArray = Object?.values(state);

		if (!areStatesEqual && !isMissingSelection) {
			setSaveLoading(true);

			let arg = {
				url: updateRegularHours,
				method: "POST",
				data: {
					businessHours: regularHoursArray,
				},
				auth: true,
			};

			const response = await fetchFuntion(arg);

			// updateRegularHours(token, regularHoursArray).then((response) => {
			if (response?.code === "SUCCESS") {
				setRegularHours(response?.businessHours);
				toast.success("Your changes have been saved", toastSettings);
				setSaveLoading(false);
				setTimeout(() => {
					toggleEditHours();
				}, 1000);
			} 
			// });
		} else if (isMissingSelection) {
			toast.error(
				"Please select operational business hours for all days of the week and try again",
				toastSettings
			);
		} else {
			toast.success("Your changes have been saved", toastSettings);
			setTimeout(() => {
				toggleEditHours();
			}, 1000);
		}
	};

	const handleCloseCheck = (slot, event) => {
		dispatch({
			type: ACTIONS.UPDATECLOSED,
			payload: {
				dayOfWeekId: slot?.dayOfWeekId,
				closedAllDay: event.target.checked,
			},
		});
	};

	const handleOpenCheck = (slot, event) => {
		dispatch({
			type: ACTIONS.UPDATEOPEN,
			payload: {
				dayOfWeekId: slot?.dayOfWeekId,
				openAllDay: event.target.checked,
			},
		});
	};

	const matchDay = (index) => {
		const matchedDict = regularHours?.find(
			(item) => item?.dayOfWeekId === index
		);
		const dayObject = matchedDict ? matchedDict : state[index];

		return (
			<tr key={index}>
				<td id={styles.weekDay}>{getWeekDay(dayObject?.dayOfWeekId)}</td>
				{state[dayObject?.dayOfWeekId]?.closedAllDay && (
					<td id={styles.selectorSpacer}>Closed All Day</td>
				)}
				{state[dayObject?.dayOfWeekId]?.openAllDay && (
					<td id={styles.selectorSpacer}>Open All Day</td>
				)}
				{!state[dayObject?.dayOfWeekId]?.closedAllDay &&
					!state[dayObject?.dayOfWeekId]?.openAllDay && (
						<td id={styles.timeSelectors}>
							<label
								className={[
									styles.customSelect,
									state[dayObject?.dayOfWeekId]?.openTime === ""
										? styles.placeholderSelected
										: "",
								].join(" ")}
							>
								<select
									value={state[dayObject?.dayOfWeekId]?.openTime}
									onChange={(e) => {
										dispatch({
											type: ACTIONS.OPENTIME,
											payload: {
												dayOfWeekId: dayObject?.dayOfWeekId,
												time: e.target.value,
											},
										});
									}}
								>
									<option value="">Select a Time</option>
									{hours?.map((id, index) => (
										<option key={index} value={id}>
											{getHours(id)}
										</option>
									))}
								</select>
							</label>
							<div style={{ fontSize: "10px", fontWeight: 700, color: "#222" }}>
								—
							</div>
							<label
								className={[
									styles.customSelect,
									state[dayObject?.dayOfWeekId]?.closeTime === ""
										? styles.placeholderSelected
										: "",
								].join(" ")}
							>
								<select
									value={state[dayObject?.dayOfWeekId]?.closeTime}
									onChange={(e) => {
										dispatch({
											type: ACTIONS.CLOSETIME,
											payload: {
												dayOfWeekId: dayObject?.dayOfWeekId,
												time: e.target.value,
											},
										});
									}}
								>
									<option value="">Select a Time</option>
									{hours
										?.filter(
											(el) => el >= state[dayObject?.dayOfWeekId]?.openTime
										)

										.map((id, index) => (
											<option key={index} value={id}>
												{getHours(id)}
											</option>
										))}
								</select>
							</label>
						</td>
					)}
				<td id={styles.openCheck}>
					<label className={styles.customCheckmark}>
						<input
							type="checkbox"
							checked={state[dayObject?.dayOfWeekId]?.closedAllDay}
							onChange={(event) => {
								handleCloseCheck(dayObject, event);
							}}
						/>
						<span className={styles.checkmark}></span>
						<span className={styles.label}>Closed All Day</span>
					</label>
				</td>
				<td id={styles.closeCheck}>
					<label className={styles.customCheckmark}>
						<input
							type="checkbox"
							checked={state[dayObject?.dayOfWeekId]?.openAllDay}
							onChange={(event) => {
								handleOpenCheck(dayObject, event);
							}}
						/>
						<span className={styles.checkmark}></span>
						<span className={styles.label}>Open All Day</span>
					</label>
				</td>
			</tr>
		);
	};
	useEffect(() => {
		days?.forEach((_, index) => {
			const available = regularHours?.find(
				(item) => item?.dayOfWeekId === index
			);
			if (available) {
				const initialData = {
					dayOfWeekId: available?.dayOfWeekId,
					openTime: available?.openTime ? available?.openTime : "",
					closeTime: available?.closeTime ? available?.closeTime : "",
					closedAllDay: available?.closedAllDay
						? available?.closedAllDay
						: false,
					openAllDay: available?.openAllDay ? available?.openAllDay : false,
				};

				dispatch({ type: ACTIONS.INITIALSTATE, payload: initialData });
			} else {
				dispatch({
					type: ACTIONS.INITIALSTATE,
					payload: {
						dayOfWeekId: index,
						openTime: "",
						closeTime: "",
						closedAllDay: false,
						openAllDay: false,
					},
				});
			}
		});
		setPreviousState(state);
	}, [isOpen, regularHours]);
	return (
		<>
			<div className={styles.whiteSpace}>
				<div className={styles.container}>
					<div className={styles.navbar}>
						<div className={styles.cancelBtn}>
							<CloseIcon
								onClick={toggleEditHours}
								fontSize="large"
								sx={{ color: "black" }}
							/>
						</div>
						<div className={styles.title}>Edit Regular Business Hours</div>
					</div>
					<table id={styles.editTable}>
						<tbody>{weekDays?.map((_, index) => matchDay(index))}</tbody>
					</table>
					<div className={styles.saveBtn} onClick={handleSave}>
						Save Changes
						{saveLoading && (
							<span>
								<ClipLoader color="#FFF" size={30} />
							</span>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
