
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserUrl } from "../../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./InventoryManagement.module.css";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterSelect from "./FilterSelect/FilterSelect";
import { useFetch } from "../../../useFetch";

export default function InventoryManagement() {

  const [token, setToken] = useState("");
  const [inventory, setInventory] = useState([]);
  const [allInventory, setAllInventory] = useState([]);
  const [inventoryFilter, setInventoryFilter] = useState("all");
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  let arg = {
		url: getUserUrl,
		method: "GET",
		auth: true,
	};

	const { result, loading } = useFetch(arg);

  useEffect(() => {
    if (result?.code === "SUCCESS") {
      const userInventory = Object.values(result?.user?.inventory || {});
      setInventory(userInventory);
      setAllInventory(userInventory);
    }
  }, [result]);


  const handleToggle = (id, index, value) => {
    const tempInventory = [...inventory];
    tempInventory[index].inStock = value;
    setInventory([...tempInventory]);
  };
  const handleGoBack = () => {
    navigate("../settings");
  };
  const handleFilterInventory = (filter) => {
    setInventoryFilter(filter);
    switch (filter) {
      case "All": {
        setInventory(allInventory);
        break;
      }
      case "In Stock": {
        setInventory(allInventory.filter((element) => element.inStock));
        break;
      }
      case "Out of Stock": {
        setInventory(allInventory.filter((element) => !element.inStock));
        break;
      }
      default: {
        break;
      }
    }
  };
  const handleError = (msn) => {
    toast.error(msn, { theme: "colored" });
    setShowToast(true);
  };
  return (
    <>
      <div className={styles[loading ? "container-loading" : "container"]}>
        {loading ? (
          <div className={styles["spinner"]}>
            <ClipLoader size={100} color="#000" />
          </div>
        ) : (

          <div className={styles["main-container"]}>
            {showToast && <ToastContainer />}
            <div className={styles["header-inventory-management"]}>
                <ArrowBackIcon onClick={handleGoBack} fontSize="large" style={{cursor:"pointer"}}/>
              <span className={styles["inventory-management-title"]}>
                Inventory Management
              </span>
            </div>
            <div className={styles["inventory-management-body"]}>
              <div className={styles["inventory-management-filter-row"]}>
                <div className={styles["inventory-management-filter"]}>
                  <FilterSelect key="filter" handleFilterInventory={handleFilterInventory} />
                </div>
              </div>
              {inventory?.length < 1 ? (
                <div className={styles["message"]}>
                  You currently don’t have inventory with us.
                </div>
              ) : (
                inventory?.map((element, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        styles["inventory-management-product-row-container"]
                      }
                    >
                      <div
                        className={
                          styles["inventory-management-product-name-container"]
                        }
                      >
                        <span className={styles["product-name-title"]}>
                          Product Name
                        </span>
                        <span className={styles["product-name-title"]}>
                          Price
                        </span>
                        <div
                          className={styles["toggle-switch-title-container"]}
                        >
                          <span className={styles["toggle-switch-title"]}>
                            In Stock?
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          styles["inventory-management-toggle-container"]
                        }
                      >
                        <span className={styles["product-name-text"]}>
                          {element.productName}
                        </span>
                        <span className={styles["price-name-text"]}>
                          ${element.price}
                        </span>
                        <ToggleSwitch
                          element={element}
                          id={element.skuId}
                          handleToggle={handleToggle}
                          toggle={element.inStock}
                          key={index}
                          index={index}
                          label={"Stock"}
                          token={token}
                          handleError={handleError}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
