import styles from "./DataTable.module.css";
const DataTable = ({ data, handleOpenModal, RowComponent }) => {
  return (
    <ul>
      {data?.length>0?data.map((item, index) => (
        <li key={index}>
          <RowComponent data={item} handleOpen={handleOpenModal}/>
        </li>
      )):
      <div className={styles["no-data-container"]}>
          <h3>No Data has been found for the date.</h3>
        </div>
          }
    </ul>
  );
};

export default DataTable;