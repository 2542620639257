import React, { useRef } from "react";
import styles from "./EmailModal.module.css";

export default function EmailModal({ isOpen, setIsOpen }) {
	const textRef = useRef(null);
	const copyToClipboard = () => {
		// navigator.clipboard.writeText(textRef.current.innerText)

		var copyText = document.getElementById("email");
		var textArea = document.createElement("textarea");
		var emailContainer = document.getElementById("emailContainer");
		var emailContainer2 = document.getElementById("emailContainer2");
		textArea.value = copyText.textContent;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("Copy");
		textArea.remove();

		emailContainer.style.display = "none";
		emailContainer2.style.display = "block";
		setTimeout(() => {
			emailContainer2.style.display = "none";

			emailContainer.style.display = "block";
		}, 1000);
	};
	return (
		isOpen && (
			<div className={styles.modalOverlay}>
				<div className={styles.emailModal}>
					<div id="emailContainer">
						Please contact{" "}
						<span
							id="email"
							className={styles.emailStyle}
							ref={textRef}
							onClick={copyToClipboard}
						>
							support@gesture.vip
						</span>
						<br />
						to update your email
					</div>
					<div style={{ display: "none" }} id="emailContainer2">
						{" "}
						Copied
					</div>
					<div className={styles.confirmBtn}>
						<button
							className={styles.btnStyle}
							onClick={() => setIsOpen(false)}
						>
							OK
						</button>
					</div>
				</div>
			</div>
		)
	);
}
